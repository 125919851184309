<template>
  <layout>
    <!--    <container class="py-0">-->
    <!--      <carousel-no-img />-->
    <!--    </container>-->
    <!--    <container>-->
    <!--      <funded-by />-->
    <!--    </container>-->
    <!--    <container>-->
    <!--      <featured-no-img />-->
    <!--    </container>-->
    <container class="py-8 px-4">
      <list-main />
    </container>
  </layout>
</template>

<script>
import Layout from '@/views/Layout';
import Container from '@/components/common/Container';
import { mapActions, mapState } from 'vuex';
import ListMain from '@/views/List/ListMain';
import { doScrollTo } from '@/utils';

export default {
  name: 'List',
  components: { ListMain, Container, Layout },
  methods: mapActions('meetings', ['getMeetings']),
  computed: mapState('meetings', ['loading', 'data']),
  created() {
    doScrollTo('body');
  }
};
</script>

<style scoped></style>
