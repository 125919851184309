import { Auth } from 'aws-amplify';
import Vue from 'vue';
import router from '@/router';
import LocalStorageService from '@/core/services/localStorage.service';

const state = () => ({
  loading: false,
  isAuthenticated: false,
  error: ''
});

const getters = {};

const mutations = {
  setLoading(state, status) {
    state.loading = status;
  },

  setIsAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },

  setError(state, error) {
    state.error = error;
  }
};

const actions = {
  authenticateSuccess({ commit }) {
    Vue.prototype.$api.data.setHeader(LocalStorageService.getToken());
    commit('setIsAuthenticated', true);
  },
  authenticateFail({ commit }, message) {
    LocalStorageService.destroy();
    commit('setIsAuthenticated', false);
    commit('setError', message);
  },
  async autoSignIn({ commit, dispatch }, referer) {
    commit('setLoading', true);
    try {
      const expiresAt = LocalStorageService.getExpiresAt();
      const accessToken = LocalStorageService.getToken();

      if (expiresAt && new Date() > expiresAt) {
        dispatch('authenticateFail', 'Token expired');
        return false;
      } else if (accessToken) {
        dispatch('authenticateSuccess');
        return true;
      } else {
        const {
          data: { access_token, expires_in }
        } = await Vue.prototype.$api.auth.getToken(referer);
        LocalStorageService.saveToken(access_token);
        LocalStorageService.saveExpiresAt(new Date().getTime() + expires_in * 1000);
        dispatch('authenticateSuccess');
        return true;
      }
    } catch (e) {
      console.log(e);
      dispatch('authenticateFail', e.response.data.message);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async signOut({ commit }) {
    await Auth.signOut();
    commit('setIsAuthenticated', false);
    await router.push('/login').catch((e) => console.warn(e));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
