import VueGtag from 'vue-gtag';
import Vue from 'vue';
import { getApplicationConfig } from '@/core/config/applicationConfig';
import router from '../router';

Vue.use(
  VueGtag,
  {
    config: {
      id: getApplicationConfig().GATrackingId,
      // params: {
      //   send_page_view: false
      // }
    }
    // pageTrackerEnabled: true,
    // appName: 'ASCO 2022 Annual Meeting Library'
  },
  router
);
