<template>
  <v-footer class="footer py-16 fs-14 text-left text-sm-center" style="color: #2c353c" absolute app>
    <container>
      <div>© 2022 American Society of Clinical Oncology (ASCO). All Rights Reserved Worldwide.</div>
      <a class="d-block mt-2" href="https://www.asco.org/about-asco" target="_blank">
        <strong> About ASCO </strong>
      </a>
      <div class="mt-4">Application created and hosted by Springer Healthcare.</div>
      <v-row class="mt-2 justify-start justify-sm-center">
        <v-col class="flex-grow-0">
          <router-link to="/about">
            <strong> About </strong>
          </router-link>
        </v-col>
        <v-col class="flex-grow-0">
          <a href="https://springerhealthcare.com/privacy-cookies/" target="_blank">
            <strong> Privacy </strong>
          </a>
        </v-col>
      </v-row>
    </container>
  </v-footer>
</template>

<script>
import Container from '@/components/common/Container';
export default {
  name: 'Footer',
  components: { Container }
};
</script>

<style scoped lang="scss">
.footer {
  background: rgba(255, 255, 255, 0.5) !important;
  box-shadow: 0px -4px 35px rgba(157, 185, 249, 0.25);
  text-align: center;
}
</style>
