import baseAxios from 'axios';

import { getApplicationConfig } from '@/core/config/applicationConfig';
import store from '@/store';

/**
 * Service to call HTTP request via Axios
 */
class BaseApiService {
  constructor(resource = '/') {
    const applicationConfig = getApplicationConfig();
    this.axios = baseAxios.create({
      baseURL: applicationConfig.server.ApiEndpoint + resource
    });
    this.axios.interceptors.response.use(null, (error) => {
      if (error.config && error.response && error.response.status === 403) {
        return store.dispatch('authentication/authenticateFail', error.response.data.message);
      }
      return Promise.reject(error);
    });
  }

  /**
   * Set the default HTTP request headers
   */
  setHeader(accessToken) {
    this.axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  /**
   * Send the GET HTTP request
   * @param path
   * @returns {*}
   */
  async get(path, config) {
    return this.axios.get(path, config);
  }

  /**
   * Set the POST HTTP request
   * @param path
   * @param payload
   * @returns {*}
   */
  async post(path, payload, config) {
    return this.axios.post(`${path}`, payload, config);
  }

  /**
   * Send the UPDATE HTTP request
   * @param path
   * @param payload
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  async update(path, payload) {
    return this.axios.put(path, payload);
  }

  /**
   * Send the PUT HTTP request
   * @param path
   * @param payload
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  async put(path, payload) {
    return this.axios.put(path, payload);
  }

  /**
   * Send the PUT HTTP request
   * @param path
   * @param payload
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  async patch(path, payload) {
    return this.axios.patch(path, payload);
  }

  /**
   * Send the DELETE HTTP request
   * @param path
   * @returns {*}
   */
  async delete(path) {
    return this.axios.delete(path).catch((error) => {
      // console.log(error);
      throw new Error(`ApiService ${error}`);
    });
  }
}

export default BaseApiService;
