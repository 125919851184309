import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import vuetify from './plugins/vuetify';
import './plugins/vue-gtag';
import './filters';
// import apollo from './plugins/apollo';
import './plugins/vue-plyr';
import { Amplify } from 'aws-amplify';
import { getApplicationConfig } from './core/config/applicationConfig';
import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';
import '@/scss/global.scss';

const { server } = getApplicationConfig();

import api from './plugins/api';

Vue.config.productionTip = false;

Vue.filter('capitalize', function (value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    // region: amplify.region,
    userPoolId: server.UserPoolId,
    identityPoolId: server.IdentityPoolId,
    userPoolWebClientId: server.UserPoolClientId
  },
  API: {
    endpoint: [
      {
        endpoint: server.ApiEndpoint
        // region: amplify.region
      }
    ]
  }
});

new Vue({
  store,
  router,
  vuetify,
  // apolloProvider: apollo,
  api,
  render: function (h) {
    return h(App);
  },
  el: '#app'
});
