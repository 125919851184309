<template>
  <v-card @click="click" :to="href" class="relative w-100 meeting mx-auto d-flex flex-column">
    <div class="absolute badge">
      <badge :class="{ 'primary-blue': meeting.contentTypeGroupLabel === 'Sessions' }" :label="meeting | displayType" />
    </div>
    <v-card-text class="pt-12">
      <v-row dense justify="start">
        <v-col v-if="meeting.mediaTypes && meeting.mediaTypes.includes('Abstracts')" class="flex-grow-0">
          <v-img width="35px" src="@/assets/icon/abstract.svg"></v-img>
        </v-col>
        <v-col v-if="meeting.mediaTypes && meeting.mediaTypes.includes('Videos')" class="flex-grow-0">
          <v-img width="35px" src="@/assets/icon/video.svg"></v-img>
        </v-col>
        <v-col v-if="meeting.mediaTypes && meeting.mediaTypes.includes('Slides')" class="flex-grow-0">
          <v-img width="35px" src="@/assets/icon/slides.svg"></v-img>
        </v-col>
        <v-col v-if="meeting.mediaTypes && meeting.mediaTypes.includes('Posters')" class="flex-grow-0">
          <v-img width="35px" src="@/assets/icon/poster.svg"></v-img>
        </v-col>
        <v-col v-if="!meeting.mediaTypes || !meeting.mediaTypes.length" class="flex-grow-0">
          <div style="height: 35px"></div>
        </v-col>
      </v-row>
      <!--      <div style="white-space: pre-wrap; text-overflow: ellipsis" class="supportive-grey&#45;&#45;text fs-14 fw-400 opacity-6">-->
      <!--        {{ meeting.sessionDates | displayDateTimeRange }}-->
      <!--      </div>-->
      <div
        v-if="!!meeting.meetingTypeName"
        class="overflow-ellipsis-3 mt-3 fs-14 fw-700 supportive-grey--text"
        v-html="meeting.meetingTypeName"
      />
      <div
        v-if="!!meeting.title"
        class="overflow-ellipsis-3 mt-3 fs-18 fw-700 supportive-grey--text"
        v-html="meeting.title"
      />
      <!--      <div-->
      <!--        v-if="!!meeting.summary"-->
      <!--        class="overflow-ellipsis-3 mt-3 fs-16 fw-400 supportive-grey&#45;&#45;text"-->
      <!--        v-html="meeting.summary"-->
      <!--      />-->
      <v-divider class="mt-3" />
      <table class="mt-3">
        <tbody>
          <tr v-if="!!meeting.primaryTrack">
            <td class="fs-12 pr-3 fw-700 supportive-grey--text opacity-6 ws-nowrap d-flex">Track</td>
            <td class="fs-12 pl-3 fw-400 supportive-grey--text opacity-6" v-text="meeting.primaryTrack" />
          </tr>
          <tr v-if="!!meeting.firstAuthor" class="mt-2">
            <td class="fs-12 pr-3 fw-700 supportive-grey--text opacity-6 ws-nowrap d-flex">First author</td>
            <td class="fs-12 pl-3 fw-400 supportive-grey--text opacity-6" v-text="meeting.firstAuthor.displayName" />
          </tr>
          <!--          <tr v-if="!!meeting.sessionDates && !!meeting.sessionDates.start" class="mt-2">-->
          <!--            <td class="fs-12 pr-3 fw-700 supportive-grey&#45;&#45;text opacity-6 ws-nowrap d-flex">Session date</td>-->
          <!--            <td style="white-space: pre-wrap" class="fs-12 pl-3 fw-400 supportive-grey&#45;&#45;text opacity-6">-->
          <!--              {{ meeting.sessionDates | displayDateTimeRange }}-->
          <!--            </td>-->
          <!--          </tr>-->
          <tr v-if="!!meeting.abstract" class="mt-2">
            <td
              class="fs-12 pr-3 fw-700 supportive-grey--text opacity-6 ws-nowrap d-flex"
              v-text="
                [meeting.abstract.abstractNumber && 'Abstract', meeting.abstract.posterBoardNumber && 'Poster']
                  .filter(Boolean)
                  .join('/')
              "
            />
            <td
              class="fs-12 pl-3 fw-400 supportive-grey--text opacity-6"
              v-text="[meeting.abstract.abstractNumber, meeting.abstract.posterBoardNumber].filter(Boolean).join('/')"
            />
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
import Badge from '@/components/common/Badge';
export default {
  components: { Badge },
  props: {
    meeting: {}
  },
  computed: {
    href() {
      if (this.meeting.contentTypeDisplayLabel === 'Abstract & Presentation') {
        return `/presentations/${this.meeting.contentId}`;
      }
      return `/sessions/${this.meeting.contentId}`;
    }
  },
  name: 'PresentationCardNoImg',
  methods: {
    click() {
      if (this.meeting.contentTypeDisplayLabel === 'Abstract & Presentation') {
        this.$gtag.event('click_on_presentation', {
          page_path: this.href,

          event_category: 'presentation',
          event_label: this.href,
          event_page_title: this.meeting.title
        });
      } else {
        this.$gtag.event('click_on_session', {
          page_path: this.href,

          event_category: 'session',
          event_label: this.href,
          event_page_title: this.meeting.title
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.meeting::v-deep.v-card {
  border-radius: 20px;
  &:not(.v-sheet--outlined) {
    box-shadow: 0px 2px 35px rgba(157, 185, 249, 0.2);
  }

  .badge {
    top: 10px;
    left: -12px;
  }
}
</style>
