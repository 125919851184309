<template>
  <div class="d-flex align-center">
    <span class="ws-nowrap mr-5 label"> Sort by </span>
    <v-select
      close-on-click
      close-on-content-click
      v-model="sortBy"
      hide-details
      content=""
      :menu-props="{ offsetY: true, nudgeTop: -8, contentClass: 'a-menu__content' }"
      outlined
      :items="items"
      filled
      append-icon="mdi-chevron-down"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'Sorter',
  data() {
    return {
      items: [
        { text: 'Relevancy', value: 'Relevancy' },
        { text: 'Newest', value: 'Newest' },
        { text: 'Oldest', value: 'Oldest' },
        { text: 'Order within session', value: 'OrderWithinSession' },
        { text: 'Abstract browse', value: 'AbstractBrowse' }
      ]
    };
  },
  methods: mapMutations('meetings', ['changeData']),
  computed: {
    ...mapState('meetings', {
      sortByFromStore: 'sortBy'
    }),
    sortBy: {
      get() {
        return this.sortByFromStore;
      },
      set(sortBy) {
        this.changeData({
          key: 'sortBy',
          value: sortBy
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.v-select::v-deep.v-select {
  min-width: 215px;
  fieldset {
    border: none;
  }
  .v-input__slot {
    border-radius: 16px;
    min-height: unset;
    height: auto;
    background: transparent !important;
    &:hover {
      background: rgba(#00447c, 0.1) !important;
    }
  }
  .v-input__append-inner {
    margin-top: 4px;
  }
  .v-select__selection {
    color: #00447c;
    font-weight: 700;
    font-size: 14px;
  }
  .v-icon {
    color: #00447c;
  }
}

.label {
  font-weight: 400;
  font-size: 14px;
  color: #00447c;
}
</style>
