<template>
  <div>
    <v-row>
      <v-col cols="12" md="9" offset-md="3">
        <div class="mx-auto">
          <search-bar />
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-3 mt-md-4">
      <v-col cols="12" md="3">
        <a-filter />
      </v-col>
      <v-col id="meeting-list" cols="12" md="9">
        <filter-options />
        <list-content />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import AFilter from '@/views/List/AFilter';
import ListContent from '@/views/List/ListContent';
import { mapActions, mapMutations, mapState } from 'vuex';
import { topicsToQueryString } from '@/utils';
import SearchBar from '@/views/List/SearchBar';
import { doScrollTo } from '@/utils';
import FilterOptions from '@/views/List/FilterOptions';

export default {
  name: 'ListMain',
  components: { FilterOptions, SearchBar, ListContent, AFilter },
  methods: {
    ...mapActions('meetings', ['getMeetings']),
    ...mapMutations('meetings', ['changeData'])
  },
  created() {
    const { mediaTypes, primaryTracks, sessionTypes, topics, q, searchFields, sortBy, contentTypeDisplayLabels } =
      this.filters;
    this.getMeetings({
      q: q ? q : '*',
      pageSize: this.pageSize,
      mediaTypes,
      primaryTracks,
      sessionTypes,
      topics: topicsToQueryString(topics),
      pageNumber: this.page,
      searchFields,
      sortBy,
      contentTypeDisplayLabels
    });
  },
  computed: {
    ...mapState('meetings', [
      'primaryTracks',
      'sessionTypes',
      'mediaTypes',
      'topics',
      'page',
      'pageSize',
      'q',
      'searchFields',
      'sortBy',
      'contentTypeDisplayLabels'
    ]),
    filters() {
      return {
        primaryTracks: this.primaryTracks,
        sessionTypes: this.sessionTypes,
        mediaTypes: this.mediaTypes,
        topics: this.topics,
        q: this.q,
        searchFields: this.searchFields,
        sortBy: this.sortBy,
        contentTypeDisplayLabels: this.contentTypeDisplayLabels
      };
    }
  },
  watch: {
    filters: function ({
      mediaTypes,
      primaryTracks,
      sessionTypes,
      topics,
      q,
      searchFields,
      sortBy,
      contentTypeDisplayLabels
    }) {
      if (this.page !== 1) {
        this.changeData({
          key: 'page',
          value: 1
        });
      } else {
        this.getMeetings({
          q: q ? q : '*',
          pageSize: this.pageSize,
          pageNumber: 1,
          mediaTypes,
          primaryTracks,
          sessionTypes,
          topics: topicsToQueryString(topics),
          searchFields,
          sortBy,
          contentTypeDisplayLabels
        });
      }
    },
    page: async function (page) {
      doScrollTo('body');
      const { mediaTypes, primaryTracks, sessionTypes, topics, q, searchFields, sortBy, contentTypeDisplayLabels } =
        this.filters;
      await this.getMeetings({
        q: q ? q : '*',
        pageSize: this.pageSize,
        pageNumber: page,
        mediaTypes,
        primaryTracks,
        sessionTypes,
        topics: topicsToQueryString(topics),
        searchFields,
        sortBy,
        contentTypeDisplayLabels
      });
    }
  }
};
</script>

<style scoped></style>
