var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"relative w-100 meeting mx-auto d-flex flex-column",attrs:{"to":`/presentations/${_vm.presentation.contentId}`}},[_c('div',{staticClass:"absolute badge"},[_c('badge',{attrs:{"label":_vm.presentation.presentation.presentationType}})],1),_c('v-card-text',{staticClass:"pt-12"},[_c('v-row',{attrs:{"dense":"","justify":"start"}},[(_vm.presentation.abstract)?_c('v-col',{staticClass:"flex-grow-0"},[_c('v-img',{attrs:{"width":"35px","src":require("@/assets/icon/abstract.svg")}})],1):_vm._e(),(_vm.presentation.mediaVideos && _vm.presentation.mediaVideos.length)?_c('v-col',{staticClass:"flex-grow-0"},[_c('v-img',{attrs:{"width":"35px","src":require("@/assets/icon/video.svg")}})],1):_vm._e(),(_vm.presentation.mediaSlides && _vm.presentation.mediaSlides.length)?_c('v-col',{staticClass:"flex-grow-0"},[_c('v-img',{attrs:{"width":"35px","src":require("@/assets/icon/slides.svg")}})],1):_vm._e(),(_vm.presentation.mediaPosters && _vm.presentation.mediaPosters.length)?_c('v-col',{staticClass:"flex-grow-0"},[_c('v-img',{attrs:{"width":"35px","src":require("@/assets/icon/poster.svg")}})],1):_vm._e(),(
          !_vm.presentation.abstract &&
          (!_vm.presentation.mediaVideos || !_vm.presentation.mediaVideos.length) &&
          (!_vm.presentation.mediaSlides || !_vm.presentation.mediaSlides.length) &&
          (!_vm.presentation.mediaPosters || !_vm.presentation.mediaPosters.length)
        )?_c('v-col',{staticClass:"flex-grow-0"},[_c('div',{staticStyle:{"height":"35px"}})]):_vm._e()],1),(!!_vm.presentation.presentation.title)?_c('div',{staticClass:"overflow-ellipsis-3 mt-3 fs-18 fw-700 supportive-grey--text",attrs:{"title":"presentation.presentation.title"},domProps:{"innerHTML":_vm._s(_vm.presentation.presentation.title)}}):_vm._e(),_c('v-divider',{staticClass:"mt-3"}),_c('table',{staticClass:"mt-3"},[_c('tbody',[(!!_vm.presentation.presentation.speakers && _vm.presentation.presentation.speakers.length)?_c('tr',{staticClass:"mt-2"},[_c('td',{staticClass:"fs-12 pr-3 fw-700 supportive-grey--text opacity-6 ws-nowrap d-flex"},[_vm._v("Speakers")]),_c('td',{staticClass:"fs-12 pl-3 fw-400 supportive-grey--text opacity-6",domProps:{"textContent":_vm._s(
              _vm.presentation.presentation.speakers
                .map(({ displayName, publicationOrganization }) => `${displayName} - ${publicationOrganization}`)
                .join(', ')
            )}})]):_vm._e(),(!!_vm.presentation.abstract)?_c('tr',{staticClass:"mt-2"},[_c('td',{staticClass:"fs-12 pr-3 fw-700 supportive-grey--text opacity-6 ws-nowrap d-flex"},[_vm._v("Abstract No")]),_c('td',{staticClass:"fs-12 pl-3 fw-400 supportive-grey--text opacity-6",domProps:{"textContent":_vm._s(_vm.presentation.abstract.abstractNumber)}})]):_vm._e()])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }