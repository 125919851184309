<template>
  <loading :loading="loading" :loaded="!!data">
    <related-meeting-carousel v-if="!!data" :meetings="data.data.search.result.groups.hits" />
  </loading>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Loading from '@/components/common/Loading';
import RelatedMeetingCarousel from '@/views/Detail/RelatedMeetingCarousel';

export default {
  name: 'RelatedMeetingCarouselContainer',
  components: { RelatedMeetingCarousel, Loading },
  props: ['sessionId'],
  async created() {
    await this.getRelatedMeetings({
      sessionId: this.sessionId,
      pageNumber: 1,
      pageSize: 5000
    });
  },
  methods: mapActions('relatedMeetings', ['getRelatedMeetings']),
  computed: {
    ...mapState('relatedMeetings', ['loading', 'data'])
  }
};
</script>

<style scoped></style>
