import Vue from 'vue';
import Vuex from 'vuex';
import meetings from './modules/meetings';
import authentication from './modules/authentication';
import carouselMeetings from './modules/carouselMeetings';
import featuredMeetings from './modules/featuredMeetings';
import presentation from './modules/presentation';
import filters from './modules/filters';
import session from './modules/session';
import relatedMeetings from './modules/relatedMeetings';

Vue.use(Vuex);
Vue.config.devtools = true;

export default new Vuex.Store({
  state: {
    testValue: 'Hello world!'
  },
  mutations: {},
  actions: {},
  modules: {
    meetings,
    authentication,
    carouselMeetings,
    featuredMeetings,
    presentation,
    filters,
    session,
    relatedMeetings
  }
});
