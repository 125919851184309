<template>
  <div class="mt-6">
    <vue-plyr ref="plyr" :options="options">
      <video controls crossorigin playsinline :data-poster="video.previewHiRes | makeSrc">
        <source :src="video.mp4 | makeSrc" type="video/mp4" />
      </video>
    </vue-plyr>
    <div class="fs-14 fw-400 mt-8" style="white-space: pre-line; line-height: 2">
      {{ media.transcript }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoContent',
  props: {
    video: Object,
    media: {
      transcript: String | null
    }
  },
  data() {
    return {
      options: {}
    };
  },
  methods: {
    trackVideoPlayed() {
      this.$gtag.event('play_video', {
        page_path: this.$route.fullPath,

        event_category: 'presentation',
        event_label: this.$options.filters.makeSrc(this.video.mp4)
      });
    }
  },
  mounted() {
    this.$refs.plyr.player.on('play', this.trackVideoPlayed);
  },
  beforeDestroy() {
    this.$refs.plyr.player.off('play', this.trackVideoPlayed);
  }
};
</script>

<style scoped></style>
