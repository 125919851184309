<template>
  <v-container class="py-0" :style="{ maxWidth: $props.maxWidth + 'px' }"><slot /></v-container>
</template>
<script>
export default {
  name: 'Container',
  props: {
    maxWidth: {
      required: false,
      type: [String, Number],
      default: 1170
    }
  }
};
</script>
