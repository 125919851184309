<template>
  <v-app-bar height="85" app>
    <container class="d-flex">
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img
            alt="ASCO Logo"
            class="shrink mr-2"
            contain
            src="@/assets/logo.svg"
            transition="scale-transition"
            width="200"
            height="44"
          />
        </router-link>
      </div>
      <div class="flex-grow-1 d-flex align-center">
        <v-img class="mx-auto d-none d-md-block" contain max-width="220px" src="@/assets/title.png"></v-img>
      </div>
      <div v-if="!$vuetify.breakpoint.mobile">
        <router-link
          class="text-decoration-none"
          v-for="item in menuItems"
          :key="item.name"
          :to="item.link ? item.link : $route"
        >
          <a-button
            class="ml-4"
            :style="item.style"
            v-if="item.type === 'BUTTON'"
            color="green"
            :square="true"
            :xSmall="true"
            @click="item.onClick"
          >
            {{ item.name }}
          </a-button>
          <v-btn
            class="fs-14 fw-400 primary-blue--text text-none"
            :style="item.style || {}"
            v-else
            text
            @click="item.onClick"
          >
            {{ item.name }}</v-btn
          >
        </router-link>
        <!--        <search-menu />-->
      </div>

      <v-menu content-class="a-menu__content" v-if="$vuetify.breakpoint.mobile" bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="item in menuItems" :key="item.name">
            <v-list-item-title>
              <router-link class="text-decoration-none" :to="item.link ? item.link : $route">
                <a-button
                  class="mt-4"
                  :style="item.style"
                  v-if="item.type === 'BUTTON'"
                  color="green"
                  :square="true"
                  :xSmall="true"
                  @click="item.onClick"
                >
                  {{ item.name }}
                </a-button>
                <v-btn v-else class="fs-14 fw-400 primary-blue--text text-none" text @click="item.onClick">
                  {{ item.name }}</v-btn
                >
              </router-link>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </container>
  </v-app-bar>
</template>
<script>
import { mapActions } from 'vuex';
import Container from '@/components/common/Container';
import AButton from '@/components/common/AButton';

export default {
  name: 'AppBar',
  components: { AButton, Container },
  data() {
    return {
      menuItems: [
        {
          name: 'Home',
          link: '/'
        },
        {
          name: 'About',
          link: '/about'
        },
        {
          name: 'Request Certificate of Participation',
          style: {
            maxWidth: '161px',
            marginTop: this.$vuetify.breakpoint.mobile ? '16px' : 0,
            marginLeft: this.$vuetify.breakpoint.mobile ? 0 : '16px'
          },
          type: 'BUTTON',
          onClick() {
            window.open('https://certificateservices.springerhealthcare.com/asco/2022am/brazil/', '_blank');
          }
        }
      ]
    };
  },
  methods: mapActions('authentication', ['signOut'])
};
</script>
<style scoped lang="scss">
.v-app-bar::v-deep.v-app-bar {
  background: rgba(255, 255, 255, 0.8) !important;
  box-shadow: 0px 4px 24px rgba(157, 185, 249, 0.25) !important;
  backdrop-filter: blur(20px);
}
</style>
