import BaseApiService from '@/core/services/baseApi.service';
import { getApplicationConfig } from '@/core/config/applicationConfig';
import qs from 'qs';

const {
  api: { meetingIds, contentTypeDisplayLabels: defaultContentTypeDisplayLabels }
} = getApplicationConfig();

export default class DataService extends BaseApiService {
  constructor() {
    super('/');
  }

  getPresentation(presentationId) {
    return this.get('meta-data', {
      params: {
        presentationId
      }
    });
  }

  getMeetings({
    q,
    pageNumber,
    pageSize,
    mediaTypes,
    sessionTypes,
    primaryTracks,
    topics,
    searchFields,
    sortBy,
    contentTypeDisplayLabels
  }) {
    const payload = {
      q,
      meetingIds,
      contentTypeDisplayLabel:
        contentTypeDisplayLabels && contentTypeDisplayLabels.length
          ? contentTypeDisplayLabels
          : defaultContentTypeDisplayLabels
    };
    if (mediaTypes && mediaTypes.length) {
      payload.mediaTypes = mediaTypes;
    }

    if (sessionTypes && sessionTypes.length) {
      payload.sessionTypes = sessionTypes;
    }

    if (primaryTracks && primaryTracks.length) {
      payload.primaryTracks = primaryTracks;
    }

    if (topics && topics.length) {
      payload.topics = topics;
    }

    if (searchFields) {
      payload.searchFields = searchFields;
    }

    if (sortBy) {
      payload.sortBy = sortBy;
    }

    return this.post('meetings', payload, {
      params: {
        pageNumber,
        pageSize
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      }
    });
  }

  getFilters({ q }) {
    return this.get('meeting-filters', {
      params: {
        q,
        meetingIds
      }
    });
  }

  getSession(sessionId) {
    return this.get(`session/${sessionId}`);
  }

  getRelatedMeetings({ sessionId, pageNumber, pageSize }) {
    const payload = {
      q: '*',
      meetingIds,
      contentTypeDisplayLabel: defaultContentTypeDisplayLabels,
      sessionIds: [sessionId]
    };
    return this.post('meetings', payload, {
      params: {
        pageNumber,
        pageSize
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      }
    });
  }
}
