import BaseApiService from '@/core/services/baseApi.service';

export default class AuthService extends BaseApiService {
  constructor() {
    super('/auth');
  }

  getToken(referer) {
    return this.get('/token', {
      params: {
        referer
      }
    });
  }
}
