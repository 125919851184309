import Vue from 'vue';

const state = () => ({
  loading: false,
  data: null,

  // Filter
  primaryTracks: [],
  sessionTypes: [],
  mediaTypes: [],
  contentTypeDisplayLabels: [],
  topics: [],

  //Paging
  page: 1,
  pageSize: 12,

  // Search
  searchText: '',
  q: '',
  searchFields: 'ALL',

  // Sort
  sortBy: 'Relevancy'
});

const getters = {};

const mutations = {
  loading(state, status) {
    state.loading = status;
  },
  succeed(state, data) {
    state.data = data;
  },
  changeData(state, { key, value }) {
    state[key] = value;
  }
};

const actions = {
  async getMeetings(
    { commit },
    {
      q,
      pageNumber,
      pageSize,
      mediaTypes,
      sessionTypes,
      primaryTracks,
      topics,
      searchFields,
      sortBy,
      contentTypeDisplayLabels
    }
  ) {
    commit('loading', true);
    const { data } = await Vue.prototype.$api.data.getMeetings({
      q,
      pageNumber,
      pageSize,
      mediaTypes,
      sessionTypes,
      primaryTracks,
      topics,
      searchFields,
      sortBy,
      contentTypeDisplayLabels
    });
    commit('succeed', data);
    commit('loading', false);
    return data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
