<template>
  <div class="mt-6">
    <div ref="mainSlider" class="splide pa-0">
      <div class="splide__track">
        <ul class="splide__list">
          <li class="splide__slide" v-for="item in slide.pages" :key="item.pageHiRes.path">
            <v-img style="border-radius: 20px" :src="item.pageHiRes | makeSrc" />
          </li>
        </ul>
      </div>
    </div>
    <div class="mt-5">
      <div id="thumbnail" ref="thumbnailSlider" class="splide pa-0">
        <div class="splide__track">
          <ul class="splide__list">
            <li class="splide__slide" v-for="item in slide.pages" :key="item.pageHiRes.path">
              <v-img style="border-radius: 5px" :src="item.pageHiRes | makeSrc" />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="text-center mt-8">
      <a-button @click="clickDownload" :href="slide.pptDeck | makeSrc" color="blue" rounded> Download Slides </a-button>
    </div>
  </div>
</template>

<script>
import Splide from '@splidejs/splide';
import AButton from '@/components/common/AButton';
export default {
  name: 'SlidesContent',
  components: { AButton },
  props: ['slide'],
  mounted() {
    const main = new Splide(this.$refs.mainSlider, {
      pagination: false,
      classes: {
        arrow: 'splide__arrow a-splide__arrow'
      }
    });

    const thumbnails = new Splide(this.$refs.thumbnailSlider, {
      fixedWidth: 104,
      isNavigation: true,
      gap: 10,
      focus: 'center',
      pagination: false,
      arrows: false
    });
    main.sync(thumbnails);
    main.mount();
    thumbnails.mount();

    main.on('move', (newIndex) => {
      this.$gtag.event('navigate_on_slide', {
        page_path: this.$route.fullPath,

        event_category: 'presentation',
        event_label: this.$options.filters.makeSrc(this.slide.pages[newIndex].pageHiRes)
      });
    });
  },
  methods: {
    clickDownload() {
      this.$gtag.event('download_slide', {
        page_path: this.$route.fullPath,

        event_category: 'presentation',
        event_label: this.$options.filters.makeSrc(this.slide.pptDeck)
      });
    }
  }
};
</script>

<style scoped lang="scss">
#thumbnail::v-deep {
  .splide__slide {
    border: solid 1px rgba(#00447c, 0.3);
    &.is-active {
      border: solid 3px #00447c;
    }
  }
}

:global {
}
</style>
