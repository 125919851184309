<template>
  <v-chip class="fw-400 fs-14 primary-blue--text" v-bind="$attrs" v-on="$listeners">
    <slot />
  </v-chip>
</template>

<script>
export default {
  name: 'Chip',
  inheritAttrs: false
};
</script>

<style scoped lang="scss">
.v-chip::v-deep.v-chip {
  border-radius: 6px;
  color: #00447c;
  &:not(.v-chip--active) {
    background: #d3e8e6;
  }
  .mdi-close::before {
    color: #00447c;
  }
}
</style>
