var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'd-flex flex-wrap',
    {
      'mb-6':
        _vm.primaryTracks.length ||
        _vm.sessionTypes.length ||
        _vm.mediaTypes.length ||
        _vm.topics.length ||
        _vm.contentTypeDisplayLabels.length
    }
  ],staticStyle:{"gap":"15px"}},[_vm._l((_vm.primaryTracks),function(primaryTrack){return _c('chip',{key:primaryTrack,attrs:{"close-icon":"mdi-close","close":""},on:{"click:close":function($event){return _vm.remove('primaryTracks', primaryTrack)}}},[_vm._v(" Primary Track: "+_vm._s(primaryTrack)+" ")])}),_vm._l((_vm.sessionTypes),function(sessionType){return _c('chip',{key:sessionType,attrs:{"close-icon":"mdi-close","close":""},on:{"click:close":function($event){return _vm.remove('sessionTypes', sessionType)}}},[_vm._v(" Session Type: "+_vm._s(sessionType)+" ")])}),_vm._l((_vm.mediaTypes),function(mediaType){return _c('chip',{key:mediaType,attrs:{"close-icon":"mdi-close","close":""},on:{"click:close":function($event){return _vm.remove('mediaTypes', mediaType)}}},[_vm._v(" Media Type: "+_vm._s(mediaType)+" ")])}),_vm._l((_vm.contentTypeDisplayLabels),function(contentType){return _c('chip',{key:contentType,attrs:{"close-icon":"mdi-close","close":""},on:{"click:close":function($event){return _vm.remove('contentTypeDisplayLabels', contentType)}}},[_vm._v(" Content Type: "+_vm._s(contentType)+" ")])}),_vm._l((_vm.minimizedTopics),function(topic){return _c('chip',{key:topic.key,attrs:{"close-icon":"mdi-close","close":""},on:{"click:close":function($event){return _vm.removeTopics(topic)}}},[_vm._v(" Topic: "+_vm._s(topic.key)+" ")])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }