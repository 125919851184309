<template>
  <div class="mt-4">
    <h2 class="fs-18 fw-400 primary-blue--text">
      Speaker: {{ speaker.displayName }}
    </h2>
    <p class="fs-14 mt-2 primary-blue--text" v-if="speaker.publicationOrganization">
      {{ speaker.publicationOrganization }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'BlankContent',
  props: ['speaker']
};
</script>

<style scoped></style>
