import Vue from 'vue';
import VueRouter from 'vue-router';
import List from '@/views/List/List';
import store from '@/store';
import Detail from '@/views/Detail/Detail';
import Session from '@/views/Session/Session';
import About from '@/views/About/About';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: List,
    meta: { private: true }
  },
  {
    path: '/presentations/:presentationId',
    name: 'Presentation',
    component: Detail,
    meta: { private: true }
  },
  {
    path: '/sessions/:sessionId',
    name: 'Session',
    component: Session,
    meta: { private: true }
  },
  {
    path: '/about',
    name: 'About',
    component: About
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  if (store.state.authentication.isAuthenticated) {
    next();
  } else {
    await store.dispatch('authentication/autoSignIn', document.referrer);
    next();
  }
});

export default router;
