<template>
  <div class="text-center">
    <v-dialog persistent content-class="a-dialog" v-model="open" width="600">
      <v-card class="a-card">
        <v-img
          contain
          :aspect-ratio="290 / 36.77"
          style="border-radius: 0"
          class="mx-auto"
          src="@/assets/logo.svg"
          max-width="290px"
        ></v-img>
        <p class="mt-6 text-center mb-0 fw-400 fs-14 supportive-grey--text">
          Sorry, this site is only available to authenticated users arriving from authorized websites. If you think you
          are seeing this message in error, please contact <a href="mailto:asco@springer.com">asco@springer.com</a>
        </p>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'RejectedDialog',
  props: ['open']
};
</script>

<style scoped></style>
