<template>
  <div>
    <div class="mt-6">
      <light-box :src="poster.previewHiRes | makeSrc" />
    </div>
    <div class="text-center mt-8">
      <!--      TODO: wait for backend api to fill data-->
      <a-button @click="clickDownload" target="_blank" :href="poster.previewHiRes | makeSrc" color="blue" rounded>
        Download Poster
      </a-button>
    </div>
  </div>
</template>

<script>
import LightBox from '@/components/common/LightBox';
import AButton from '@/components/common/AButton';
import axios from 'axios';
export default {
  name: 'PosterContent',
  components: { LightBox, AButton },
  props: ['poster'],
  data() {
    return {
      overlay: false
    };
  },
  methods: {
    async downloadItem() {
      const url = this.$options.filters.makeSrc(this.poster.previewHiRes);
      const response = await axios.get(url, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = this.poster.previewHiRes.split('/')[-1];
      link.click();
      URL.revokeObjectURL(link.href);
    },
    clickDownload() {
      this.$gtag.event('download_poster', {
        page_path: this.$route.fullPath,

        event_category: 'presentation',
        event_label: this.$options.filters.makeSrc(this.poster.previewHiRes)
      });
    }
  }
};
</script>

<style scoped></style>
