<template>
  <v-divider />
</template>

<script>
export default {
  name: 'Divider'
};
</script>

<style scoped lang="scss">
.v-divider::v-deep {
  opacity: 0.3;
  border-color: #9db9f9 !important;
}
</style>
