<template>
  <div>
    <div class="fw-700 fs-32 primary-blue--text mt-15">Other presentations from this session</div>
    <div ref="mainSlider" class="splide pa-0 mt-8">
      <div class="splide__track">
        <ul class="splide__list">
          <li style="width: 280px" class="splide__slide" v-for="(meeting, index) in meetings" :key="index">
            <presentation-card-no-img style="height: 100%" :meeting="meeting" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Splide from '@splidejs/splide';
import PresentationCardNoImg from '@/components/PresentationCardNoImg';

export default {
  name: 'RelatedMeetingCarousel',
  components: { PresentationCardNoImg },
  props: ['meetings'],
  mounted() {
    const main = new Splide(this.$refs.mainSlider, {
      pagination: false,
      arrows: true,
      autoWidth: true,
      gap: 28,
      padding: {
        left: 12
      },
      perMove: 1,
      lazyLoad: true,
      classes: {
        arrow: 'splide__arrow a-splide__arrow'
      }
    });
    main.mount();
  }
};
</script>

<style scoped></style>
