<template>
  <layout>
    <container class="py-16 px-4">
      <loading :loading="loading" :loaded="!!data">
        <template v-slot:skeleton>
          <div>
            <v-row no-gutters>
              <v-col cols="12" md="3">
                <v-skeleton-loader width="100%" type="list-item-two-line@3" />
              </v-col>
              <v-col
                cols="12"
                md="9"
                :class="{
                  'pl-8': !$vuetify.breakpoint.mobile
                }"
              >
                <divider v-if="$vuetify.breakpoint.mobile" class="my-4" />
                <v-skeleton-loader width="100%" type="heading" />
                <br />
                <v-skeleton-loader width="50%" type="heading" />
                <v-row class="mt-3 mt-md-8">
                  <v-col v-for="index in 12" :key="index" class="d-flex align-stretch" cols="12" lg="4" sm="6">
                    <v-skeleton-loader width="100%" type="article, list-item-avatar-three-line@2" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </template>

        <v-row v-if="!!data" no-gutters>
          <v-col cols="12" md="3">
            <badge :ribbon="false" :label="result.sessionType" class="mb-8 primary-blue" />
            <div v-if="result.sessionDates && result.sessionDates.start">
              <div class="fw-700 fs-12 supportive-grey--text opacity-6" style="line-height: 1.5">Time</div>
              <div class="fw-400 mt-1 fs-12 supportive-grey--text opacity-6" style="line-height: 1.5">
                {{ result.sessionDates | displayDateTimeRange }}
              </div>
            </div>
            <div class="mt-4" v-if="result.chair && result.chair.length">
              <div class="fw-700 fs-12 supportive-grey--text opacity-6" style="line-height: 1.5">Chair(s)</div>
              <div
                class="fw-400 mt-1 fs-12 supportive-grey--text opacity-6"
                style="line-height: 1.5"
                v-text="result.chair.map(({ displayName }) => displayName).join(', ')"
              />
            </div>
            <div class="mt-4" v-if="result.tracks && result.tracks.length">
              <div class="fw-700 fs-12 supportive-grey--text opacity-6" style="line-height: 1.5">Track(s)</div>
              <div
                class="fw-400 mt-1 fs-12 supportive-grey--text opacity-6"
                style="line-height: 1.5"
                v-text="result.tracks.map(({ track }) => track).join(', ')"
              />
            </div>
          </v-col>
          <v-col
            cols="12"
            md="9"
            :class="{
              'pl-8': !$vuetify.breakpoint.mobile
            }"
          >
            <divider v-if="$vuetify.breakpoint.mobile" class="my-4" />
            <h1 class="fs-24 fw-700 primary-blue--text" v-html="result.title" />
            <v-row class="mt-3 mt-md-8">
              <v-col
                v-for="presentation in result.presentations"
                :key="presentation.contentId"
                class="d-flex align-stretch"
                cols="12"
                lg="4"
                sm="6"
              >
                <session-presentation-card :presentation="presentation" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </loading>
    </container>
  </layout>
</template>

<script>
import Layout from '@/views/Layout';
import Divider from '@/components/common/Divider';
import Container from '@/components/common/Container';
import { mapActions, mapMutations, mapState } from 'vuex';
import Loading from '@/components/common/Loading';
import SessionPresentationCard from '@/components/SessionPresentationCard';
import { doScrollTo } from '@/utils';
import Badge from "@/components/common/Badge";

export default {
  name: 'Session',
  components: {
    SessionPresentationCard,
    Loading,
    Divider,
    Layout,
    Container,
    Badge
  },
  computed: {
    ...mapState('session', ['loading', 'data']),
    result() {
      return this.data.data.session.result;
    }
  },
  data() {
    return {
      panel: null,
      currentLink: null
    };
  },
  methods: {
    ...mapMutations('session', ['succeed']),
    ...mapActions('session', ['getSession'])
  },
  created() {
    doScrollTo('body');
    this.getSession(this.$route.params.sessionId);
  },
  destroyed() {
    this.succeed(null);
  }
};
</script>

<style scoped lang="scss">
.v-navigation-drawer::v-deep {
  .v-navigation-drawer__border {
    background-color: #9db9f9 !important;
    opacity: 0.3;
  }
}

.side-bar::v-deep {
  border-right: solid 1px #e2eafc;
}
</style>
