import moment from 'moment-timezone';
export default function displayDateTimeRange({ start, end }) {
  const TIMEZONE_REGION = 'EST';
  const DATE_FORMAT = 'MMM Do YYYY';
  const TIME_FORMAT = 'HH:mm a';
  const TIMEZONE_FORMAT = 'z';
  const mStart = moment(start).tz(TIMEZONE_REGION);
  const mEnd = moment(end).tz(TIMEZONE_REGION);
  if (start && end) {
    const isSameDate = mStart.isSame(mEnd, 'date');
    if (isSameDate) {
      return `${mStart.format(DATE_FORMAT)}\n${mStart.format(TIME_FORMAT)} - ${mEnd.format(
        TIME_FORMAT
      )} ${mStart.format(TIMEZONE_FORMAT)}`;
    }
  }
  if (!start && !end) {
    return '';
  }
}
