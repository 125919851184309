<template>
  <div>
    <v-row v-if="loading" no-gutters justify="center">
      <slot name="skeleton" />
      <v-progress-circular v-if="!$slots.skeleton" indeterminate color="primary" />
    </v-row>
    <template v-else-if="!!loaded">
      <slot />
    </template>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    loading: Boolean,
    loaded: undefined
  }
};
</script>

<style scoped></style>
