<template>
  <layout>
    <container class="py-0 mb-16">
      <v-img src="@/assets/disclaimer.png" />
      <div style="max-width: 770px" class="mx-auto pt-10">
        <h1 class="fw-700 fs-40 primary-blue--text">About</h1>
        <div class="mt-2 supportive-grey--text fs-14 fw-400">
          <div class="mt-4">
            This content is made available solely for educational and informational purposes and personal use only.
            Unauthorized reproduction is prohibited.
          </div>
          <div class="mt-4">
            ASCO assumes no responsibility for any injury or damage to persons or property arising out of or related to
            any use of the material contained in this publication or to any errors or omissions. The content does not
            constitute medical or legal advice, and is not intended for use in the diagnosis or treatment of individual
            conditions. The content is not comprehensive, and should not be used as a substitute for consultation with a
            licensed medical professional or other traditional source of medical information. The mention of any
            product, service, organization, activity or therapy should not be construed as an ASCO endorsement. ASCO
            assumes no responsibility for any injury or damage to persons or property arising out of or related to the
            content or the website. Viewers are advised to check the appropriate medical literature and the product
            information currently provided by the manufacturer of each drug to be administered to verify, among other
            matters, the dosage, method, and duration of administration, or contraindications. Viewers are also
            encouraged to contact the manufacturer with questions about the features or limitations of any products.
          </div>
          <div class="mt-4">
            Access to the content on this website is not equivalent to meeting registration and users are not eligible
            for CME credits but can request a certificate of participation.
          </div>
          <div class="mt-4">For assistance please contact <a href="mailto:asco@springer.com">asco@springer.com</a></div>
        </div>
      </div>
    </container>
  </layout>
</template>

<script>
import Layout from '@/views/Layout';
import Container from '@/components/common/Container';
import { doScrollTo } from '@/utils';
export default {
  name: 'Disclaimer',
  components: { Container, Layout },
  created() {
    doScrollTo('body');
  }
};
</script>

<style scoped lang="scss">
.hero {
  background: red;
  height: 200px;
}
.ul::v-deep {
  list-style-type: none;
  padding-left: 0;
  li {
    padding: 16px 0 16px 16px;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      width: 7px;
      height: 7px;
      background: #96dac5;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
  }
}
</style>
