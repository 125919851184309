<template>
  <v-btn :class="{ square, xSmall, small }" v-bind="$attrs" v-on="$listeners">
    <slot />
  </v-btn>
</template>

<script>
export default {
  props: ['square', 'xSmall', 'small'],
  name: 'AButton',
  inheritAttrs: false
};
</script>

<style scoped lang="scss">
.v-btn::v-deep.v-btn {
  white-space: pre-wrap !important;
  height: auto !important;
  border-radius: 30px;
  box-shadow: 0px 4px 20px rgba(157, 185, 249, 0.2);
  text-transform: none;
  padding: 10px 32px !important;
  font-weight: 400;
  font-size: 16px;
  min-height: 56px;
  .v-btn__content {
    max-width: 100%;
  }
  &.blue {
    background-color: #00447c !important;
    border-color: #00447c !important;
    color: white;
  }
  &.green {
    background-color: #028764 !important;
    border-color: #028764 !important;
    color: white;
  }
  &.square {
    border-radius: 10px;
  }
  &.xSmall {
    padding: 10px !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
  }
  &.small {
    padding: 9px 60px !important;
    font-size: 14px;
    min-height: unset;
  }
}
</style>
