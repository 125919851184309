import api from '../core/services/api';
import Vue from 'vue';

const apiPlugin = {
  install(Vue) {
    Vue.prototype.$api = api;
  }
};

Vue.use(apiPlugin);

export default apiPlugin;
