<template>
  <div
    :class="[
      'a-badge fw-400 primary-green fs-12 px-4 white--text',
      {
        'is-ribbon': ribbon
      }
    ]"
    v-text="label"
  />
</template>

<script>
export default {
  name: 'Badge',
  props: {
    label: {
      type: String,
      required: false
    },
    ribbon: {
      type: Boolean,
      required: false,
      default: true
    }
  }
};
</script>

<style scoped lang="scss">
.a-badge::v-deep {
  padding-top: 5px;
  padding-bottom: 5px;
  transform-style: preserve-3d;
  position: relative;
  border-radius: 6px;
  white-space: nowrap;
  &.is-ribbon {
    &:before {
      background-color: #2c353c;
      content: '';
      width: 9px;
      height: 6px;
      position: absolute;
      left: 3px;
      bottom: -5px;
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
      transform: translateZ(-1px);
    }
  }
}
</style>
