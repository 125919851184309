<template>
  <v-card :to="`/presentations/${presentation.contentId}`" class="relative w-100 meeting mx-auto d-flex flex-column">
    <div class="absolute badge">
      <badge :label="presentation.presentation.presentationType" />
    </div>
    <v-card-text class="pt-12">
      <v-row dense justify="start">
        <v-col v-if="presentation.abstract" class="flex-grow-0">
          <v-img width="35px" src="@/assets/icon/abstract.svg"></v-img>
        </v-col>
        <v-col v-if="presentation.mediaVideos && presentation.mediaVideos.length" class="flex-grow-0">
          <v-img width="35px" src="@/assets/icon/video.svg"></v-img>
        </v-col>
        <v-col v-if="presentation.mediaSlides && presentation.mediaSlides.length" class="flex-grow-0">
          <v-img width="35px" src="@/assets/icon/slides.svg"></v-img>
        </v-col>
        <v-col v-if="presentation.mediaPosters && presentation.mediaPosters.length" class="flex-grow-0">
          <v-img width="35px" src="@/assets/icon/poster.svg"></v-img>
        </v-col>
        <v-col
          v-if="
            !presentation.abstract &&
            (!presentation.mediaVideos || !presentation.mediaVideos.length) &&
            (!presentation.mediaSlides || !presentation.mediaSlides.length) &&
            (!presentation.mediaPosters || !presentation.mediaPosters.length)
          "
          class="flex-grow-0"
        >
          <div style="height: 35px"></div>
        </v-col>
      </v-row>
      <!--      <div style="white-space: pre-wrap; text-overflow: ellipsis" class="supportive-grey&#45;&#45;text fs-14 fw-400 opacity-6">-->
      <!--        {{ meeting.sessionDates | displayDateTimeRange }}-->
      <!--      </div>-->
      <!--      <div-->
      <!--        v-if="!!presentation.presentation.presentationType"-->
      <!--        class="overflow-ellipsis-3 mt-3 fs-14 fw-700 supportive-grey&#45;&#45;text"-->
      <!--        v-html="presentation.presentation.presentationType"-->
      <!--      />-->
      <div
        title="presentation.presentation.title"
        v-if="!!presentation.presentation.title"
        class="overflow-ellipsis-3 mt-3 fs-18 fw-700 supportive-grey--text"
        v-html="presentation.presentation.title"
      />
      <!--      <div-->
      <!--        v-if="!!meeting.summary"-->
      <!--        class="overflow-ellipsis-3 mt-3 fs-16 fw-400 supportive-grey&#45;&#45;text"-->
      <!--        v-html="meeting.summary"-->
      <!--      />-->
      <v-divider class="mt-3" />
      <table class="mt-3">
        <tbody>
          <!--          <tr v-if="!!meeting.primaryTrack">-->
          <!--            <td class="fs-12 pr-3 fw-700 supportive-grey&#45;&#45;text opacity-6 ws-nowrap d-flex">Track</td>-->
          <!--            <td class="fs-12 pl-3 fw-400 supportive-grey&#45;&#45;text opacity-6" v-text="meeting.primaryTrack" />-->
          <!--          </tr>-->
          <tr v-if="!!presentation.presentation.speakers && presentation.presentation.speakers.length" class="mt-2">
            <td class="fs-12 pr-3 fw-700 supportive-grey--text opacity-6 ws-nowrap d-flex">Speakers</td>
            <td
              class="fs-12 pl-3 fw-400 supportive-grey--text opacity-6"
              v-text="
                presentation.presentation.speakers
                  .map(({ displayName, publicationOrganization }) => `${displayName} - ${publicationOrganization}`)
                  .join(', ')
              "
            />
          </tr>
          <!--          <tr-->
          <!--            v-if="!!presentation.presentation.presentationDates && !!presentation.presentation.presentationDates.start"-->
          <!--            class="mt-2"-->
          <!--          >-->
          <!--            <td class="fs-12 pr-3 fw-700 supportive-grey&#45;&#45;text opacity-6 ws-nowrap d-flex">Session date</td>-->
          <!--            <td style="white-space: pre-wrap" class="fs-12 pl-3 fw-400 supportive-grey&#45;&#45;text opacity-6">{{ presentation.presentation.presentationDates | displayDateTimeRange }}</td>-->
          <!--          </tr>-->
          <tr v-if="!!presentation.abstract" class="mt-2">
            <td class="fs-12 pr-3 fw-700 supportive-grey--text opacity-6 ws-nowrap d-flex">Abstract No</td>
            <td
              class="fs-12 pl-3 fw-400 supportive-grey--text opacity-6"
              v-text="presentation.abstract.abstractNumber"
            />
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
import Badge from '@/components/common/Badge';
export default {
  components: { Badge },
  props: ['presentation'],
  name: 'SessionPresentationCard'
};
</script>

<style scoped lang="scss">
.meeting::v-deep.v-card {
  border-radius: 20px;
  &:not(.v-sheet--outlined) {
    box-shadow: 0px 2px 35px rgba(157, 185, 249, 0.2);
  }

  .badge {
    top: 10px;
    left: -12px;
  }
}
</style>
