<template>
  <div
    :class="[
      'd-flex flex-wrap',
      {
        'mb-6':
          primaryTracks.length ||
          sessionTypes.length ||
          mediaTypes.length ||
          topics.length ||
          contentTypeDisplayLabels.length
      }
    ]"
    style="gap: 15px"
  >
    <chip
      v-for="primaryTrack in primaryTracks"
      :key="primaryTrack"
      close-icon="mdi-close"
      close
      @click:close="remove('primaryTracks', primaryTrack)"
    >
      Primary Track: {{ primaryTrack }}
    </chip>
    <chip
      v-for="sessionType in sessionTypes"
      :key="sessionType"
      close-icon="mdi-close"
      close
      @click:close="remove('sessionTypes', sessionType)"
    >
      Session Type: {{ sessionType }}
    </chip>
    <chip
      v-for="mediaType in mediaTypes"
      :key="mediaType"
      close-icon="mdi-close"
      close
      @click:close="remove('mediaTypes', mediaType)"
    >
      Media Type: {{ mediaType }}
    </chip>
    <chip
      v-for="contentType in contentTypeDisplayLabels"
      :key="contentType"
      close-icon="mdi-close"
      close
      @click:close="remove('contentTypeDisplayLabels', contentType)"
    >
      Content Type: {{ contentType }}
    </chip>
    <chip
      v-for="topic in minimizedTopics"
      :key="topic.key"
      close-icon="mdi-close"
      close
      @click:close="removeTopics(topic)"
    >
      Topic: {{ topic.key }}
    </chip>
  </div>
</template>

<script>
import Chip from '../../components/common/Chip';
import { mapMutations, mapState } from 'vuex';
import { topicsToQueryString } from '../../utils';
export default {
  name: 'FilterOptions',
  components: { Chip },
  computed: {
    ...mapState('meetings', ['primaryTracks', 'sessionTypes', 'mediaTypes', 'topics', 'contentTypeDisplayLabels']),
    minimizedTopics() {
      const minimized = topicsToQueryString(this.topics);
      const flatten = (array, level) => {
        return array.reduce((newArray, { key, children }) => {
          if (!children) {
            return newArray.concat([
              {
                key,
                level
              }
            ]);
          } else return newArray.concat(flatten(children, level + 1));
        }, []);
      };
      return flatten(minimized, 1);
    }
  },
  methods: {
    ...mapMutations('meetings', ['changeData']),
    remove(key, value) {
      const index = this[key].findIndex((p) => p === value);
      this[key].splice(index, 1);
      this.changeData({
        key,
        value: this[key]
      });
    },
    removeTopics({ key, level }) {
      const topics = this.topics.filter((option) => {
        return option[`level${level}`] !== key;
      });
      this.changeData({
        key: 'topics',
        value: topics
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
