const ACCESS_TOKEN_KEY = 'access_token';
const EXPIRES_AT_KEY = 'expires_at';
const IS_AGREE_KEY = 'is_agree';

export const getToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const getExpiresAt = () => {
  return window.localStorage.getItem(EXPIRES_AT_KEY);
};

export const getIsAgree = () => {
  return window.localStorage.getItem(IS_AGREE_KEY);
};

export const saveToken = (token) => {
  window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

export const saveExpiresAt = (timeInMili) => {
  window.localStorage.setItem(EXPIRES_AT_KEY, timeInMili);
};

export const saveIsAgree = (isAgree) => {
  window.localStorage.setItem(IS_AGREE_KEY, isAgree);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const destroy = () => {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  window.localStorage.removeItem(EXPIRES_AT_KEY);
  window.localStorage.removeItem(IS_AGREE_KEY);
};

export default { getToken, saveToken, destroyToken, saveExpiresAt, getExpiresAt, destroy, saveIsAgree, getIsAgree };
