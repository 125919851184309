<template>
  <div class="text-center">
    <v-dialog persistent content-class="a-dialog" v-model="open" width="600">
      <v-card class="a-card">
        <v-img
          contain
          :aspect-ratio="290 / 36.77"
          style="border-radius: 0"
          class="mx-auto"
          src="@/assets/logo.svg"
          max-width="290px"
        ></v-img>
        <h1 class="text-center mt-8 fw-700 fs-18 primary-blue--text">
          Welcome to the 2022 ASCO Annual Meeting On Demand
        </h1>
        <p class="text-center mt-5 mb-0 fw-400 fs-14 supportive-grey--text">
          To access the ASCO22 Meeting Videos, Presentation Slides, Abstracts and Posters you confirm you have read and
          agree to the
          <a target="_blank" href="https://www.asco.org/about-asco/legal/terms-use" class="primary-green--text"
            >terms of use</a
          >
        </p>
        <div class="text-center mt-5">
          <v-checkbox v-model="isAgree" class="mx-auto d-inline-block my-0 py-0" hide-details dense>
            <template v-slot:label>
              <label class="fs-14 fw-400 supportive-grey--text"> I have read and agree </label>
            </template>
          </v-checkbox>
          <br />
          <small v-if="!!error" class="mt-1 red--text" v-text="error" />
        </div>
        <div class="text-center mt-5">
          <a-button @click="onContinueClick" color="green" :small="true" class="primary-green"> Continue </a-button>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AButton from '@/components/common/AButton';
export default {
  name: 'AcceptedDialog',
  components: { AButton },
  props: ['open', 'onClick'],
  methods: {
    onContinueClick() {
      if (!this.isAgree) {
        this.error = 'You have to agree with terms and conditions';
      } else {
        this.onClick();
      }
    }
  },
  data() {
    return {
      error: '',
      isAgree: false
    };
  }
};
</script>

<style scoped></style>
