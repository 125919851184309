<template>
  <div>
    <app-bar />
    <v-main>
      <slot />
    </v-main>
    <app-footer />
  </div>
</template>

<script>
import AppBar from '@/components/global/AppBar';
import Footer from '@/components/global/Footer';

export default {
  name: 'Layout',
  components: { AppFooter: Footer, AppBar }
};
</script>
