import _ from 'lodash';
import store from '@/store';

export function doScrollTo(selector, offset = 0) {
  const el = document.querySelector(selector);
  if (!el) return;
  const y = el.getBoundingClientRect().top + window.scrollY + offset;
  window.scrollTo({
    top: y, // negative value acceptable
    left: 0,
    behavior: 'smooth'
  });
}

export function topicsToQueryString(topics) {
  if (!store.state.filters.data) return [];

  const originalTopics = store.state.filters.data.data.search.result.aggregations.topic;

  const calcDeepLength = (input) => {
    if (Array.isArray(input)) {
      if (input.length) {
        return input.reduce((result, child) => (result += calcDeepLength(child)), 0);
      } else return 1;
    }
    if (input && typeof input === 'object') {
      if (input.children) return calcDeepLength(input.children);
    }
    return 1;
  };

  const getData = (parentData, levels) => {
    if (!levels.length) return parentData;
    return getData(parentData.find(({ key }) => key === levels[0]).children, levels.slice(1));
  };

  const buildQuery = (selectedTopics, levelNumber, levelKeys) => {
    return _(selectedTopics)
      .groupBy(`level${levelNumber}`)
      .toPairs()
      .map(([key, children]) => {
        const query = {
          key
        };
        if (calcDeepLength(getData(originalTopics, levelKeys.concat([key]))) > children.length) {
          query.children = buildQuery(children, levelNumber + 1, levelKeys.concat([key]));
        }
        return query;
      })
      .value();
  };

  return buildQuery(topics, 1, []);
}
