<template>
  <v-app class="a-app">
    <router-view />
    <loading :loading="loading" :loaded="!loading">
      <accepted-dialog :open="isAuthenticated && !isAgree && isPrivate" :on-click="onClick" />
      <rejected-dialog :open="!isAuthenticated" />
    </loading>
  </v-app>
</template>
<script>
import AcceptedDialog from '@/components/AcceptedDialog';
import RejectedDialog from '@/components/RejectedDialog';
import Loading from '@/components/common/Loading';
import { mapState } from 'vuex';
import LocalStorageService from '@/core/services/localStorage.service';
export default {
  name: 'App',
  components: { Loading, RejectedDialog, AcceptedDialog },
  computed: mapState('authentication', ['loading', 'isAuthenticated', 'error']),
  data() {
    return {
      isAgree: +LocalStorageService.getIsAgree(),
      isPrivate: this.$route.meta.private
    };
  },
  updated() {
    this.isAgree = +LocalStorageService.getIsAgree();
    this.isPrivate = this.$route.meta.private;
  },
  methods: {
    onClick() {
      this.isAgree = true;
      LocalStorageService.saveIsAgree('1');
    }
  }
};
</script>

<style scoped lang="scss">
.a-app::v-deep.v-application {
  background: #f1f4fb;
}
</style>
