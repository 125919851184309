<template>
  <v-text-field class="a-text-field" v-bind="$attrs" v-on="$listeners">
    <slot />
  </v-text-field>
</template>

<script>
export default {
  name: 'TextField',
  inheritAttrs: false
};
</script>

<style scoped lang="scss">
.a-text-field::v-deep {
  .v-input__prepend-inner {
    z-index: 1;
  }
  fieldset {
    border-radius: 20px;
    border: 1px solid rgba(0, 68, 124, 0.3);
    background: white;
  }
  input {
    color: #00447c;
  }
}
</style>
