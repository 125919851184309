<template>
  <div>
    <v-img style="border-radius: 20px" class="pointer" @click="overlay = !overlay" :src="src" />
    <v-overlay :value="overlay">
      <v-img class="pointer" max-height="100vh" max-width="100vw" contain @click="overlay = !overlay" :src="src" />
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: 'LightBox',
  props: ['src'],
  data() {
    return {
      overlay: false
    };
  }
};
</script>

<style scoped></style>
