<template>
  <v-row no-gutters>
    <v-col>
      <text-field
        height="54"
        prepend-inner-icon="mdi-magnify"
        class="align-content-center d-flex justify-center"
        clearable
        hide-details
        outlined
        placeholder="Enter to search"
        v-model="searchText"
        @input="onSearch"
      />
    </v-col>
    <v-col :style="{ maxWidth: 300, flexBasis: '30%' }" class="flex-grow-0">
      <v-select
        hide-details
        v-model="searchFields"
        content=""
        height="54"
        :menu-props="{ offsetY: true, nudgeTop: -8, contentClass: 'a-menu__content' }"
        outlined
        :items="items"
        filled
        append-icon="mdi-chevron-down"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import TextField from '@/components/common/TextField';
import debounce from 'lodash/debounce';
import { mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {
      items: [
        { text: 'All', value: 'ALL' },
        { text: 'Title', value: 'TITLE' },
        { text: 'Author', value: 'AUTHOR' }
      ]
    };
  },
  computed: {
    ...mapState('meetings', {
      searchTextInStore: 'searchText',
      searchFieldsInStore: 'searchFields'
    }),
    searchFields: {
      get() {
        return this.searchFieldsInStore;
      },
      set(searchFields) {
        this.changeData({
          key: 'searchFields',
          value: searchFields
        });
      }
    },
    searchText: {
      get() {
        return this.searchTextInStore;
      },
      set(searchText) {
        return this.changeData({
          key: 'searchText',
          value: searchText
        });
      }
    }
  },
  components: {
    TextField
  },
  name: 'SearchBar',
  methods: {
    ...mapMutations('meetings', ['changeData']),
    onSearch: debounce(function () {
      this.changeData({
        key: 'q',
        value: this.searchText
      });
      this.$gtag.event('search', {
        page_path: this.$route.fullPath,

        event_category: 'search',
        event_label: this.searchText
      });
    }, 300)
  }
};
</script>

<style scoped lang="scss">
.v-text-field::v-deep.v-text-field {
}
.v-text-field::v-deep.v-text-field:not(.v-select) {
  fieldset {
    border-color: rgba(0, 68, 124, 0.3);
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    box-shadow: 0px 4px 20px rgba(157, 185, 249, 0.2);
  }
  .v-input__slot {
    padding-left: 18px;
  }
  position: relative;
  &:after {
    position: absolute;
    content: '';
    height: 30px;
    width: 1px;
    background: #9db9f9;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    bottom: 0;
    opacity: 0.3;
  }
  .v-icon.mdi-chevron-down {
    &:before {
      font-size: 20px;
    }
    color: #00447c;
    opacity: 0.3;
  }

  .v-icon.mdi-magnify {
    color: #00447c;
    opacity: 0.3;
  }
}

.v-select::v-deep.v-select {
  fieldset {
    background: white;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    border-width: 1px;
    border-color: rgba(0, 68, 124, 0.3);
    box-shadow: 0px 4px 20px rgba(157, 185, 249, 0.2);
  }
  .v-input__slot,
  .v-input__slot:hover {
    background: transparent !important;
  }
  .v-select__selection {
    font-weight: 700;
    font-size: 14px;
    line-height: 160%;
    color: #00447c;
  }
  .v-icon {
    color: #00447c;
  }
}
</style>
