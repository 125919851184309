function getApplicationConfig() {
  var nodeEnv = process.env.NODE_ENV || 'development';
  window.nodeEnv = nodeEnv;
  const serverConfig = require(`@/core/config/applicationConfig/exports-${nodeEnv}.json`);
  return {
    ...require(`@/core/config/applicationConfig/${nodeEnv}.json`),
    server: serverConfig[`${nodeEnv}-asco-be-asco-be-stack`]
  };
}

export { getApplicationConfig };
