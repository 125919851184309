import Vue from 'vue';

const state = () => ({
  loading: false,
  data: null
});

const getters = {};

const mutations = {
  loading(state, status) {
    state.loading = status;
  },

  succeed(state, data) {
    state.data = data;
  }
};

const actions = {
  async getPresentation({ commit }, presentationId) {
    commit('loading', true);
    const { data } = await Vue.prototype.$api.data.getPresentation(presentationId);
    commit('succeed', data);
    commit('loading', false);
    return data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
