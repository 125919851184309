<template>
  <div class="pb-8">
    <div v-if="abstract.authors" id="authors" class="pt-8">
      <div class="supportive-grey--text fs-14 fw-700">Authors</div>
      <div
        class="supportive-grey--text fs-12 fw-400 mt-2"
        style="line-height: 2.2"
        v-text="abstract.authors.map(({ displayName }) => displayName).join(', ')"
      />
      <a
        id="disclosures"
        target="_blank"
        :href="
          Object.assign({}, abstract.disclosureUrl, {
            queryParams: JSON.stringify({
              id: $route.params.presentationId
            })
          }) | makeSrc
        "
        class="fs-14 fw-400 mt-2 d-inline-block primary-green--text"
        >Abstract Disclosures</a
      >
    </div>
    <div id="research-funding" class="pt-8">
      <div class="supportive-grey--text fs-14 fw-700">Research Funding:</div>
      <div
        style="line-height: 2.2"
        class="supportive-grey--text fs-12 fw-400 mt-2"
        v-text="abstract.additionalFundingSource"
      />
    </div>
    <div id="abstract-body">
      <!--                <div class="supportive-grey&#45;&#45;text fs-16 fw-700">Research Funding:</div>-->
      <div style="line-height: 2.2" class="supportive-grey--text fs-12 fw-400" v-html="abstract.fullBody" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AbstractContent',
  props: ['abstract']
};
</script>

<style scoped lang="scss">
#abstract-body::v-deep {
  b {
    padding-top: 32px;
    padding-bottom: 8px;
    font-size: 14px;
    display: block;
  }
}
</style>
