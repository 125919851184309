<template>
  <div>
    <loading :loading="loading" :loaded="!!data">
      <template v-slot:skeleton>
        <div>
          <v-skeleton-loader width="300px" type="heading" />
          <br />
          <v-row>
            <v-col v-for="index in 12" :key="index" class="d-flex align-stretch" cols="12" lg="4" sm="6">
              <v-skeleton-loader width="100%" type="article, list-item-avatar-three-line@2" />
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-if="!!data">
        <v-row class="flex-column-reverse flex-md-row" no-gutters justify="space-between" align="center">
          <v-col class="flex-grow-0">
            <div class="primary-blue--text ws-nowrap fw-400 fs-14">
              Total: {{ data.data.search.result.groups.total }}
            </div>
          </v-col>
          <v-col class="flex-grow-0">
            <sorter />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(meeting, i) in data.data.search.result.groups.hits"
            :key="i"
            class="d-flex align-stretch"
            cols="12"
            lg="4"
            sm="6"
          >
            <presentation-card-no-img :meeting="meeting" />
          </v-col>
        </v-row>
      </template>
    </loading>
    <v-pagination
      v-if="!!data"
      prev-icon="mdi-arrow-left"
      next-icon="mdi-arrow-right"
      class="a-pagination mt-12"
      v-model="page"
      :length="Math.ceil(data.data.search.result.groups.total / pageSize)"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Loading from '@/components/common/Loading';
import PresentationCardNoImg from '@/components/PresentationCardNoImg';
import Sorter from '@/views/List/Sorter';

export default {
  components: { Sorter, PresentationCardNoImg, Loading },
  computed: {
    ...mapState('meetings', {
      loading: 'loading',
      data: 'data',
      pageInStore: 'page',
      pageSize: 'pageSize'
    }),
    page: {
      get() {
        return this.pageInStore;
      },
      set(page) {
        return this.changeData({
          key: 'page',
          value: page
        });
      }
    }
  },
  methods: mapMutations('meetings', ['changeData']),
  title: 'ListContent'
};
</script>

<style scoped lang="scss">
.a-pagination::v-deep.a-pagination {
  .v-pagination__navigation {
    border-radius: 50%;
  }
}
</style>
