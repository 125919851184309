<template>
  <loading :loaded="!!data" :loading="loading">
    <template v-slot:skeleton>
      <v-skeleton-loader
        width="100%"
        type="list-item-two-line, divider, list-item-two-line, divider, list-item-two-line, divider, list-item-two-line"
      />
    </template>
    <div v-if="!!data">
      <v-row justify="space-between" align="center" no-gutters>
        <v-col class="fw-700 fs-16 primary-blue--text"> FILTERS </v-col>
        <v-col class="text-right">
          <v-btn @click="resetFilter" style="text-transform: none" class="fs-14 primary-green--text fw-400" text>
            Clear all
          </v-btn>
        </v-col>
      </v-row>
      <divider class="mt-4" />
      <v-expansion-panels multiple class="a-expansion-panels" accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="mb-2 primary-blue--text fs-14 fw-700">Primary Track</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <v-checkbox
                v-for="item in aggregations.primaryTrack"
                :key="item.key"
                :ripple="false"
                class="a-input--checkbox pt-0 mt-2"
                dense
                hide-details
                :value="item.key"
                v-model="primaryTracks"
              >
                <template v-slot:label>
                  <div class="supportive-grey--text fs-14 fw-400" v-text="item.key" />
                </template>
              </v-checkbox>
            </div>
          </v-expansion-panel-content>
          <v-divider class="a-divider" />
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="pl-1 mb-2 primary-blue--text fs-14 fw-700">Session Type</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <v-checkbox
                v-for="item in aggregations.sessionType"
                :key="item.key"
                :ripple="false"
                class="a-input--checkbox pt-0 mt-2"
                dense
                hide-details
                :value="item.key"
                v-model="sessionTypes"
              >
                <template v-slot:label>
                  <div class="supportive-grey--text fs-14 fw-400" v-text="item.key" />
                </template>
              </v-checkbox>
            </div>
          </v-expansion-panel-content>
          <v-divider class="a-divider" />
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="pl-1 mb-2 primary-blue--text fs-14 fw-700">Media Type</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <v-checkbox
                v-for="item in aggregations.mediaType"
                :key="item.key"
                :ripple="false"
                class="a-input--checkbox pt-0 mt-2"
                dense
                hide-details
                :value="item.key"
                v-model="mediaTypes"
              >
                <template v-slot:label>
                  <div class="supportive-grey--text fs-14 fw-400" v-text="item.key" />
                </template>
              </v-checkbox>
            </div>
          </v-expansion-panel-content>
          <v-divider class="a-divider" />
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="pl-1 mb-2 primary-blue--text fs-14 fw-700">Content Type</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <v-checkbox
                v-for="item in aggregations.contentTypeDisplayLabel.filter(({ key }) => key !== 'Meeting')"
                :key="item.key"
                :ripple="false"
                class="a-input--checkbox pt-0 mt-2"
                dense
                hide-details
                :value="item.key"
                v-model="contentTypeDisplayLabels"
              >
                <template v-slot:label>
                  <div class="supportive-grey--text fs-14 fw-400" v-text="item.key" />
                </template>
              </v-checkbox>
            </div>
          </v-expansion-panel-content>
          <v-divider class="a-divider" />
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="pl-1 mb-2 primary-blue--text fs-14 fw-700">Topic</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <v-treeview
                return-object
                multiple-active
                v-model="topics"
                class="a-treeview"
                dense
                item-text="key"
                selectable
                :items="treeItems"
              ></v-treeview>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </loading>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import Loading from '@/components/common/Loading';
import Divider from '@/components/common/Divider';

export default {
  name: 'AFilter',
  components: { Divider, Loading },
  computed: {
    ...mapState('filters', ['loading', 'data']),
    ...mapState('meetings', {
      primaryTracksInStore: 'primaryTracks',
      sessionTypesInStore: 'sessionTypes',
      mediaTypesInStore: 'mediaTypes',
      topicsInStore: 'topics',
      contentTypeDisplayLabelsInStore: 'contentTypeDisplayLabels'
    }),
    aggregations() {
      return this.data.data.search.result.aggregations;
    },
    primaryTracks: {
      get() {
        return this.primaryTracksInStore;
      },
      set(primaryTracks) {
        return this.changeData({
          key: 'primaryTracks',
          value: primaryTracks
        });
      }
    },
    sessionTypes: {
      get() {
        return this.sessionTypesInStore;
      },
      set(sessionTypes) {
        return this.changeData({
          key: 'sessionTypes',
          value: sessionTypes
        });
      }
    },
    mediaTypes: {
      get() {
        return this.mediaTypesInStore;
      },
      set(mediaTypes) {
        return this.changeData({
          key: 'mediaTypes',
          value: mediaTypes
        });
      }
    },
    topics: {
      get() {
        return this.topicsInStore;
      },
      set(topics) {
        return this.changeData({
          key: 'topics',
          value: topics
        });
      }
    },
    contentTypeDisplayLabels: {
      get() {
        return this.contentTypeDisplayLabelsInStore;
      },
      set(contentTypeDisplayLabels) {
        return this.changeData({
          key: 'contentTypeDisplayLabels',
          value: contentTypeDisplayLabels
        });
      }
    },
    treeItems() {
      this.aggregations.topic.forEach((level1Item) => {
        const { key: level1, children } = level1Item;
        level1Item.level1 = level1;
        level1Item.id = level1;
        children.forEach((level2Item) => {
          const { key: level2, children } = level2Item;
          level2Item.level1 = level1;
          level2Item.level2 = level2;
          level2Item.id = [level1, level2].join('|');
          children.forEach((level3Item) => {
            level3Item.level1 = level1;
            level3Item.level2 = level2;
            level3Item.level3 = level3Item.key;
            level3Item.id = [level1, level2, level3Item.key].join('|');
          });
        });
      });
      return this.aggregations.topic;
    }
  },
  methods: {
    ...mapActions('filters', ['getFilters']),
    ...mapActions('meetings', ['getMeetings']),
    ...mapMutations('meetings', ['changeData']),
    resetFilter() {
      this.changeData({
        key: 'primaryTracks',
        value: []
      });
      this.changeData({
        key: 'sessionTypes',
        value: []
      });
      this.changeData({
        key: 'mediaTypes',
        value: []
      });
      this.changeData({
        key: 'topics',
        value: []
      });
      this.changeData({
        key: 'contentTypeDisplayLabels',
        value: []
      });
    }
  },
  created() {
    if (!this.data) {
      this.getFilters({
        q: '*'
      });
    }
  }
};
</script>

<style scoped lang="scss">
.v-expansion-panel-header::v-deep {
  padding-left: 0;
}
</style>
