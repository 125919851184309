<template>
  <layout>
    <container class="py-16 px-4">
      <loading :loaded="!!data" :loading="loading">
        <template v-slot:skeleton>
          <v-row no-gutters>
            <v-col cols="12" md="3">
              <v-skeleton-loader width="100%" type="heading" />
              <br />
              <v-skeleton-loader width="100%" type="list-item-two-line@4" />
            </v-col>
            <v-col
              cols="12"
              md="9"
              :class="{
                'pl-8': !$vuetify.breakpoint.mobile
              }"
            >
              <divider v-if="$vuetify.breakpoint.mobile" class="my-4" />
              <v-skeleton-loader width="100%" type="heading" />
              <br />
              <v-skeleton-loader width="100%" type="heading" />
              <br />
              <v-skeleton-loader width="100%" type="article@10" />
            </v-col>
          </v-row>
        </template>
        <v-row no-gutters v-if="!!data">
          <v-col cols="12" md="3">
            <!--          <v-col cols="3" class="fill-height sticky pt-16" style="top: 85px">-->
            <!--            <v-navigation-drawer style="min-height: 100vh" class="pr-8" width="100%" color="transparent" permanent>-->
            <!--              -->
            <!--            </v-navigation-drawer>-->
            <v-expansion-panels
              v-if="availableTabs.includes(TAB_INDEXES.ABSTRACT)"
              class="a-expansion-panels"
              v-model="panel"
              accordion
            >
              <v-expansion-panel>
                <v-expansion-panel-header
                  :class="[
                    'media-item',
                    'primary-blue--text',
                    'fs-16',
                    {
                      selected: panel === TAB_INDEXES.ABSTRACT
                    }
                  ]"
                >
                  Abstract
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <a
                    :class="[
                      'fs-14 py-2 primary-blue--text pointer d-block transition font-none px-0',
                      {
                        'pt-0': i === 0,
                        'pb-0': i === 3,
                        'fw-700': i === currentLink
                      }
                    ]"
                    v-for="(item, i) in sections"
                    @click.prevent="doScrollTo(item.selector, -offset)"
                    :key="item.selector"
                    :href="item.selector"
                    v-text="item.label"
                  ></a>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div
              @click="panel = item.key"
              v-for="item in availableTabs
                .filter((index) => index !== TAB_INDEXES.ABSTRACT)
                .map((index) => PANELS.find(({ key }) => key === index))"
              :class="[
                'media-item primary-blue--text fs-16 pointer',
                {
                  selected: panel === item.key
                }
              ]"
              v-text="item.label"
              :key="item.key"
            />
            <divider class="mt-5 mb-4" />
            <badge :ribbon="false" :label="result.presentation.presentationType" class="mb-5" />

            <!--            <div class="py-4 fw-400 fs-12 supportive-grey&#45;&#45;text opacity-6" style="line-height: 1.5">-->
            <!--              Selected by the 2019 Annual Meeting Scientific Program Committee-->
            <!--            </div>-->
            <!--            <divider />-->
            <div class="py-4" v-if="result.abstract && result.abstract.authors && result.abstract.authors.length">
              <div class="fw-700 fs-12 supportive-grey--text opacity-6" style="line-height: 1.5">First Author</div>
              <div
                class="fw-400 mt-1 fs-12 supportive-grey--text opacity-6"
                style="line-height: 1.5"
                v-text="result.abstract.authors[0].displayName"
              />
            </div>
            <div class="py-4">
              <div class="fw-700 fs-12 supportive-grey--text opacity-6" style="line-height: 1.5">Meeting</div>
              <div
                class="fw-400 mt-1 fs-12 supportive-grey--text opacity-6"
                style="line-height: 1.5"
                v-text="result.meeting.title"
              />
            </div>
            <!--            <divider />-->
            <div v-if="result.session.sessionType" class="py-4">
              <div class="fw-700 fs-12 supportive-grey--text opacity-6" style="line-height: 1.5">Session type</div>
              <div
                class="fw-400 mt-1 fs-12 supportive-grey--text opacity-6"
                style="line-height: 1.5"
                v-text="result.session.sessionType"
              />
            </div>
            <!--            <divider />-->
            <div class="py-4">
              <div class="fw-700 fs-12 supportive-grey--text opacity-6" style="line-height: 1.5">Session title</div>
              <router-link
                v-if="data.data.presentation.result.session.sessionType"
                class="fw-400 mt-1 fs-12 supportive-grey--text opacity-6"
                style="line-height: 1.5"
                v-text="result.session.title"
                :to="{ name: 'Session', params: { sessionId: data.data.presentation.result.session.contentId } }"
              />
              <div
                v-else
                class="fw-400 mt-1 fs-12 supportive-grey--text opacity-6"
                style="line-height: 1.5"
                v-text="result.session.title"
              />
            </div>
            <!--            <divider />-->
            <div class="py-4">
              <div class="fw-700 fs-12 supportive-grey--text opacity-6" style="line-height: 1.5">Track(s)</div>
              <div
                class="fw-400 mt-1 fs-12 supportive-grey--text opacity-6"
                style="line-height: 1.5"
                v-text="result.tracks.map(({ track }) => track).join(',')"
              />
            </div>

            <div v-if="result.abstract && result.abstract.doi" class="py-4">
              <div class="fw-700 fs-12 supportive-grey--text opacity-6" style="line-height: 1.5">Doi</div>
              <div
                class="fw-400 mt-1 fs-12 supportive-grey--text opacity-6"
                style="line-height: 1.5"
                v-text="result.abstract.doi"
              />
            </div>
            <div v-if="result.abstract && result.abstract.journalCitation" class="py-4">
              <div class="fw-700 fs-12 supportive-grey--text opacity-6" style="line-height: 1.5">Journal citation</div>
              <div
                class="fw-400 mt-1 fs-12 supportive-grey--text opacity-6"
                style="line-height: 1.5"
                v-text="result.abstract.journalCitation"
              />
            </div>
            <div v-if="result.session.sessionDates && result.session.sessionDates.start" class="py-4">
              <div class="fw-700 fs-12 supportive-grey--text opacity-6" style="line-height: 1.5">Session date</div>
              <div
                class="fw-400 mt-1 fs-12 supportive-grey--text opacity-6"
                style="line-height: 1.5; white-space: pre-wrap"
              >
                {{ result.session.sessionDates | displayDateTimeRange }}
              </div>
            </div>
            <!--            <divider />-->
            <div class="py-4" v-if="result.abstract && result.abstract.abstractNumber">
              <div class="fw-700 fs-12 supportive-grey--text opacity-6" style="line-height: 1.5">Abstract number</div>
              <div
                class="fw-400 mt-1 fs-12 supportive-grey--text opacity-6"
                style="line-height: 1.5"
                v-text="result.abstract.abstractNumber"
              />
            </div>
          </v-col>
          <v-col
            cols="12"
            md="9"
            :class="{
              'pl-8': !this.$vuetify.breakpoint.mobile
            }"
          >
            <divider v-if="this.$vuetify.breakpoint.mobile" class="my-4" />
            <h1 class="fs-24 fw-700 primary-blue--text" v-html="result.presentation.title" />
            <abstract-content
              v-if="panel === TAB_INDEXES.ABSTRACT || panel === undefined"
              :abstract="result.abstract"
            />
            <video-content v-if="panel === TAB_INDEXES.VIDEO" :video="result.mediaVideos[0]" :media="result.media" />
            <slide-content v-if="panel === TAB_INDEXES.SLIDES" :slide="result.mediaSlides[0]" />
            <poster-content v-if="panel === TAB_INDEXES.POSTER" :poster="result.mediaPosters[0]" />
            <blank-content
              v-if="panel === null && result.presentation.speakers && result.presentation.speakers.length"
              :speaker="result.presentation.speakers[0]"
            />

            <related-meeting-carousel-container :session-id="data.data.presentation.result.session.contentId" />
          </v-col>
        </v-row>
      </loading>
    </container>
  </layout>
</template>

<script>
import Layout from '@/views/Layout';
import { mapActions, mapMutations, mapState } from 'vuex';
import Container from '@/components/common/Container';
import Loading from '@/components/common/Loading';
import Badge from '../../components/common/Badge';
import AbstractContent from './Content/AbstractContent';
import { doScrollTo } from '@/utils';
import Divider from '@/components/common/Divider';
import VideoContent from '@/views/Detail/Content/VideoContent';
import SlideContent from '@/views/Detail/Content/SlideContent';
import PosterContent from '@/views/Detail/Content/PosterContent';
import RelatedMeetingCarouselContainer from '@/views/Detail/RelatedMeetingCarouselContainer';
import BlankContent from '@/views/Detail/Content/BlankContent';

const SECTIONS = [
  { label: 'Authors', selector: '#authors' },
  { label: 'Abstract disclosures', selector: '#disclosures' },
  { label: 'Research Funding', selector: '#research-funding' },
  { label: 'Body', selector: '#abstract-body' }
];

const TAB_INDEXES = {
  ABSTRACT: 0,
  VIDEO: 1,
  SLIDES: 2,
  POSTER: 3
};

const PANELS = [
  { key: TAB_INDEXES.ABSTRACT, label: 'Abstract', trackingKey: 'abstract' },
  { key: TAB_INDEXES.VIDEO, label: 'Video', trackingKey: 'video' },
  { key: TAB_INDEXES.SLIDES, label: 'Slides', trackingKey: 'slide' },
  { key: TAB_INDEXES.POSTER, label: 'Poster', trackingKey: 'poster' }
];

export default {
  name: 'Detail',
  components: {
    BlankContent,
    RelatedMeetingCarouselContainer,
    PosterContent,
    SlideContent,
    VideoContent,
    Divider,
    AbstractContent,
    Badge,
    Loading,
    Container,
    Layout
  },
  computed: {
    ...mapState('presentation', ['loading', 'data']),
    result() {
      return this.data.data.presentation.result;
    },
    availableTabs() {
      return [
        !!this.result.abstract && TAB_INDEXES.ABSTRACT,
        !!(this.result.mediaVideos && this.result.mediaVideos.length) && TAB_INDEXES.VIDEO,
        !!(this.result.mediaSlides && this.result.mediaSlides.length) && TAB_INDEXES.SLIDES,
        !!(this.result.mediaPosters && this.result.mediaPosters.length) && TAB_INDEXES.POSTER
      ].filter((v) => v !== false);
    }
  },
  methods: {
    ...mapActions('presentation', ['getPresentation']),
    ...mapMutations('presentation', ['succeed']),
    doScrollTo,
    handleScroll() {
      const scrollPos = Math.floor(window.scrollY);
      SECTIONS.forEach(({ selector }, index) => {
        const currLink = document.querySelector(selector);
        if (!currLink) return;
        const y = Math.floor(currLink.getBoundingClientRect().top + scrollPos - this.offset);
        const anchor = document.querySelector(`a[href="${selector}"]`);
        if (scrollPos >= y && scrollPos <= y + currLink.clientHeight) {
          if (!anchor) return;
          this.currentLink = index;
        }
      });
    }
  },
  async created() {
    this.PANELS = PANELS;
    this.SECTIONS = SECTIONS;
    this.TAB_INDEXES = TAB_INDEXES;

    doScrollTo('body');
    this.offset = 85;
    window.addEventListener('scroll', this.handleScroll);
    await this.getPresentation(this.$route.params.presentationId);
    this.panel = this.availableTabs[0] === undefined ? null : this.availableTabs[0];
  },
  data() {
    return {
      panel: null,
      currentLink: null
    };
  },
  destroyed() {
    this.succeed(null);
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    '$route.params.presentationId': async function () {
      doScrollTo('body');
      await this.getPresentation(this.$route.params.presentationId);
      this.panel = this.availableTabs[0];
    },
    panel: function () {
      const getPanelName = () => {
        const panel = this.PANELS.find(({ key }) => key === this.panel);
        if (panel) return panel.trackingKey;
        return 'none';
      };
      this.$gtag.event('click_on_navigation_inside_presentation', {
        page_path: this.$route.fullPath,

        event_category: 'presentation',
        event_label: getPanelName()
      });
    }
  }
};
</script>

<style scoped lang="scss">
.v-navigation-drawer::v-deep {
  .v-navigation-drawer__border {
    background-color: #9db9f9 !important;
    opacity: 0.3;
  }
}

.media-item::v-deep {
  line-height: 22.4px;
  border-radius: 10px;
  min-height: auto;
  font-weight: 700;
  font-size: 14px;
  color: #00447c;
  padding: 10px 0 10px 10px;
  &.selected {
    font-weight: 700;
    background-color: rgba(157, 185, 249, 0.15);
  }
  .v-expansion-panel-header__icon {
    margin-right: 12px;
  }
}

.v-expansion-panel-content::v-deep {
  .v-expansion-panel-content__wrap {
    padding: 10px 30px;
  }
}

.side-bar::v-deep {
  border-right: solid 1px #e2eafc;
}
</style>
